import React from 'react';
import './BlogRetail.css';
import retail1 from '../../assets/retail1.jpeg';
import retail2 from '../../assets/retail2.jpeg';
import retail3 from '../../assets/retail3.png';
const BlogRetail = () => {
    return (
        <div className="blog-retail-container">
            <h2 className="blog-retail-title">Vacant Retail Spaces: Showcases for artists and makers
            </h2>
            
            <h1 className="blog-retail-title">Transforming Empty Retail Spaces into Vibrant Artist Showcases
            </h1>
            <h2 className="blog-retail-title">February 22, 2024
            </h2>         
            <div className="blog-retail-content">
                <img src={retail1} alt=" image 1" className="blog-retail-image" />
                <p>Empty storefronts and vacant <a href="https://www.swivospace.com/spaces?pub_events=has_all%3Aretail_popups" target="_blank">retail spaces</a> are an all-too-common sight in New York City. But what if these dormant locations could be transformed into dynamic hubs for artists and commerce? At <a href="https://www.swivospace.com/" target="_blank">Swivospace</a>, we're excited about the potential of repurposing these spaces as temporary showcases for local artists and makers.
                </p>
                <h2>Why It Works</h2>
                <ul>
                    <li>Fills a Need: Artists and makers in NYC often struggle to find affordable exhibition space.</li>
                    <li>Revitalizes Neighborhoods: Empty spaces can make areas feel neglected. Art exhibitions and pop-up events bring life and foot traffic.
                    </li>
                    <li>Flexible Terms: Short-term rentals allow for rotating exhibitions and creative pop-up shops.
                    </li>
                    <li>Win-Win Situation: Property owners generate income while supporting the local creative community.</li>
                </ul>
                <img src={retail2} alt=" image 2" className="blog-retail-image" />
                <h2>How to Make It Happen</h2>
                <ol>
                    <li><a href="https://www.swivospace.com/p/list-your-space" target="_blank">List Your Space:</a> If you're a property owner, list your vacant retail space on Swivospace.</li>
                    <li><b>Connect with Creatives:</b> Event coordinators can easily find and book spaces for artist showcases.</li>
                    <li><b>Transform the Space:</b> With minimal setup, empty shops become galleries, studios, or <a href="https://www.swivospace.com/spaces?pub_events=has_all%3Aretail_popups" target="_blank">pop-up stores.</a></li>
                    <li><b>Promote the Event:</b>BlogRetail Use social media and local press to draw attention to the showcase. </li>
                </ol>
                <img src={retail3} alt=" image 3" className="blog-retail-image" />
                <h2>Benefits for Artists and Makers</h2>
                <ul>
                    <li>Affordable exhibition space
                    </li>
                    <li>Exposure to new audiences</li>
                    <li>Opportunity to sell work directly to the public
                    </li>
                    <li>Networking with other artists, makers, and potential buyers
                    </li>
                </ul>
                <h2>Benefits for Property Owners</h2>
                <ul>
                    <li>Generate income from otherwise unused space
                    </li>
                    <li>Attract potential long-term tenants
                    </li>
                    <li>Contribute to community vibrancy
                    </li>
                    <li>Maintain an active, cared-for appearance of the property
                    </li>

                </ul>
                <p>By connecting vacant retail spaces with the vibrant world of local arts and crafts, we can create win-win situations that benefit property owners, creatives, and entire communities. <a href="https://www.swivospace.com/" target="_blank">Swivospace</a> is proud to facilitate these connections and help bring new life to empty spaces.</p>
            </div>
        </div>
    );
};

export default BlogRetail;