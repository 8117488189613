import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive, findOptionsForSelectFilter } from '../../util/search';
import { removeFavCompareFromLS } from '../../util/productCompareService';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy,
  FilterEvent,
} from '../../components';

import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';
import {CompareNavigationWrapper} from '../../components/index';

import css from './SearchPage.module.css';

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */
class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryFiltersOpen: false,
      currentQueryParams: props.urlQueryParams,
      pubEvents: '',
      selectedEvent:'',
    };

    this.applyFilters = this.applyFilters.bind(this);
    this.applyFiltersEvents = this.applyFiltersEvents.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.secondartyResetAll = this.secondartyResetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentDidMount() {
    const { location } = this.props.history;
    if (location.search.length > 0) {
      const [otherPartOfUri, event] = decodeURIComponent(location.search).split(':');
      this.setState({ selectedEvent: event });
    } else {
      const { history } = this.props;
      history.push('/spaces');
      removeFavCompareFromLS();
      this.setState({ selectedEvent: 'all_events' });
    }
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters(pub_events = null, pub_Eventid = null) {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    let searchParams;
    this.setState({ pubEvents: pub_Eventid, selectedEvent: pub_events });

    if (!pub_events) {
      searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    } else {
      searchParams = { pub_events, ...urlQueryParams, ...this.state.currentQueryParams };
    }

    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  secondartyResetAll(e) {
    this.resetAll(e);
    this.setState({ isSecondaryFiltersOpen: false });
  }

  applyFiltersEvents(pub_events = null, pub_Eventid = null) {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    let searchParams;
    this.setState({ pubEvents: pub_Eventid, selectedEvent: pub_events });

    if (!pub_events) {
      searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    } else if (pub_events === 'has_all:all_events') {
      this.resetAll();
    } else {
      this.cancelFilters();
      searchParams = { pub_events };
    }

    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;
    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };
        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: { ...mergedQueryParams, ...updatedURLParams, address, bounds },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          let searchParams = this.state.currentQueryParams;

          const keysList = Object.keys(searchParams);
          keysList.forEach((val, index) => {
            if (val.indexOf('capacity') > -1) {
              let capacityVal = searchParams[val] ? searchParams[val].split(',') : null;
              let minVal = searchParams[val] ? capacityVal[0] : null;
              let maxVal = searchParams[val] ? capacityVal[1] : null;
              let pub_maximumcapacity = searchParams[val] ? minVal + ',' + maxVal : null;
              searchParams = { ...searchParams, pub_maximumcapacity };
            }
          });
          const searchParamsKey = Object.keys(searchParams);
          if (
            searchParams.bookingPrice === 'hourly' &&
            searchParamsKey.includes('pub_dailyprices')
          ) {
            if (searchParams.pub_dailyprices) {
              // searchParams['price'] = searchParams['pub_dailyprices'];
              delete searchParams['pub_dailyprices'];
            }
          } else if (searchParams.bookingPrice === 'daily' && searchParamsKey.includes('price')) {
            if (searchParams.price) {
              searchParams['pub_dailyprices'] = searchParams['price'];
              delete searchParams['price'];
            }
          }
          

          if (searchParamsKey.includes('bookingPrice')) {
            delete searchParams['bookingPrice'];
          }

          // const maxParamns = { pub_minimumcapacity: '0,45', pub_maximumcapacity: '0,45' };
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      history,
      searchInURL,
      intl,
      updateCompareProductRequest,
      fetchCompareProductListingData,
    } = this.props;

    const { location } = history;
    const primaryFilters = filterConfig.filter(f => f.group === 'primary');
    const secondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0);
    const optionsEvents = findOptionsForSelectFilter('events', filterConfig);

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};

    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;
    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;
    const listingCount = intl.formatMessage({ id: 'SearchPage.listingCount' });
    const threeColumnFlag = listings.length > Number(listingCount);
    const panelClassName = classNames(css.searchListingsPanel, {
      [css.threeColumns]: threeColumnFlag
    });
    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const screenSize = typeof screen !== 'undefined' ? screen.availWidth : null;
    return (
      <div className={classes}>
        <h1 className={css.searchPageH1}><FormattedMessage id="SearchPage.h1Content" /></h1>
        <p className={css.eventMsg}><FormattedMessage id={!this.state.selectedEvent || this.state.selectedEvent.includes("all_events") ? "SearchPage.eventMsg" : "SearchPage.eventCheckSelectionMsg"}/></p>
        <SearchFiltersPrimary
          className={css.searchFiltersPrimary}
          sortByComponent={sortBy('desktop')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          selectedSecondaryFilters={selectedSecondaryFilters}
          searchInURL={searchInURL}
          {...propsForSecondaryFiltersToggle}
        >
          {screenSize && screenSize > 767 ? (
            <FilterEvent
              EventsList={optionsEvents}
              className={css.searchEvents}
              applyFilters={this.applyFiltersEvents}
              locationParmsValue={location.search}
              searchInURL={searchInURL}
              fetchCompareProductListingData={fetchCompareProductListingData}
            />
          ) : (
            ''
          )}

          {primaryFilters.map(config => {
            return (
              <FilterComponent
                key={`SearchFiltersPrimary.${config.id}`}
                idPrefix="SearchFiltersPrimary"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={this.initialValues}
                getHandleChangedValueFn={this.getHandleChangedValueFn}
                showAsPopup
                contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                locationParmsValue={location.search}
                pubEventsValue={this.state.pubEvents}
                resetAll={this.resetAll}
              />
            );
          })}
        </SearchFiltersPrimary>

        <SearchFiltersMobile
          className={`${css.searchFiltersMobile} ${css.searchEvents}`}
          urlQueryParams={urlQueryParams}
          sortByComponent={sortBy('mobile')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          resetAll={this.resetAll}
          selectedFiltersCount={selectedFiltersCount}
          EventsList={optionsEvents}
          applyFilters={this.applyFilters}
          locationParmsValue={location.search}
          applyFiltersEvents={this.applyFiltersEvents}
          searchInURL={searchInURL}
          fetchCompareProductListingData={fetchCompareProductListingData}
        >
          {filterConfig.map(config => {
            return (
              <FilterComponent
                key={`SearchFiltersMobile.${config.id}`}
                idPrefix="SearchFiltersMobile"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={this.initialValues}
                getHandleChangedValueFn={this.getHandleChangedValueFn}
                liveEdit
                showAsPopup={false}
                locationParmsValue={location.search}
                resetAll={this.resetAll}
                pubEventsValue={this.state.pubEvents}
              />
            );
          })}
        </SearchFiltersMobile>
        {isSecondaryFiltersOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <div className={css.amenitiesTile}>
              Amenities <span onClick={this.secondartyResetAll}>Reset All</span>
            </div>
            <SearchFiltersSecondary
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              applyFilters={this.applyFilters}
              cancelFilters={this.cancelFilters}
              resetAll={this.resetAll}
              onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
            >
              {secondaryFilters.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersSecondary.${config.id}`}
                    idPrefix="SearchFiltersSecondary"
                    filterConfig={config}
                    urlQueryParams={urlQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    showAsPopup={false}
                    locationParmsValue={location.search}
                    resetAll={this.resetAll}
                    pubEventsValue={this.state.pubEvents}
                  />
                );
              })}
            </SearchFiltersSecondary>
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
            <SearchResultsPanel
              className={panelClassName}
              listings={listings}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              intl={intl}
              events={this.state.selectedEvent}
              updateCompareProductRequest={updateCompareProductRequest}
              fetchCompareProductListingData={fetchCompareProductListingData}
            />
          </div>
        )}

        <CompareNavigationWrapper fetchCompareProductListingData={fetchCompareProductListingData} events={this.state.selectedEvent} threeColumnFlag={threeColumnFlag}/>
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
